import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  constructor(
    private http: HttpClient,
  ) { }

  getTags(){
    return this.http.get(environment.api+"tags");
  }
  addTag(name:string,color:string){
    const form = new FormData()
    form.append('name',name)
    form.append('color',color)
    return this.http.post(environment.api+'tags/store',form)
  }
  removeTag(id:string){
    return this.http.delete(environment.api+'tags/delete/'+id)
  }
}
