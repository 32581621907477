import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {TokenStorageService} from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthStatusService {
  connected$ = new BehaviorSubject<boolean>(false)
  constructor(
    private tokenStorageService: TokenStorageService,
  ) { }

  private  loggedIn = new BehaviorSubject<boolean>(this.tokenStorageService.isLoggedIn());
  authStatus = this.loggedIn.asObservable();

  changeAuthStatus(value: boolean){
    this.loggedIn.next(value);
    this.connected$.next(value)
  }
}
