export enum Notifier{
    AppStarted,
    FeedbackFetchSuccessful,
    FeedbackFetchFailed,
    FeedbackAddSuccessFul,
    FeedbackAddFailed,
    FeedbackUpdateSuccessful,
    FeedbackUpdateFailed,
    FeedbackDeleteSuccessful,
    FeedbackDeleteFailed,
    CommentAddSuccessful,
    CommentAddFailed,
    CommentUpdateSuccessful,
    CommentUpdateFailed,
    CommentPinSuccessful,
    CommentPinFailed,
    CommentDeleteSuccessful,
    CommentDeleteFailed,
    CommentRemoveFileSuccessful,
    CommentRemoveFileFailed,
    ReplyAddSuccessful,
    ReplyAddFailed,
    ReplyUpdateSuccessful,
    ReplyUpdateFailed,
    ReplyDeleteSuccessful,
    ReplyDeleteFailed,
    ReplyRemoveFileSuccessful,
    ReplyRemoveFileFailed,
    PlansFetchedSuccessful,
    PlansFetchedFailed,
    SubscriptionAddInSuccessful,
    SubscriptionAddInFailed,
    SubscriptionAddOutSuccessful,
    SubscriptionAddOutFailed,
    SubscriptionSelectPlanSuccessful,
    SubscriptionSelectPlanFailed,
    SubscriptionUpgradePlanSuccessful,
    SubscriptionUpgradePlanFailed,
    SubscriptionDowngradePlanSuccessful,
    SubscriptionDowngradePlanFailed,
    TradeInSuccessful,
    TradeInFailed,
    AddCardSuccessful,
    AddCardFailed,
    SubscriptionAutoPaymentPlanSuccessful,
    SubscriptionAutoPaymentPlanFailed,
    SubscriptionPeriodPlanSuccessful,
    SubscriptionPeriodPlanFailed,
    updateCompanyPeriodSuccessful,
    updateCompanyPeriodFailed,
    updateCompanyAutopaymentSuccessful,
    updateCompanyAutopaymentFailed,
    verifyEmailSuccessfullExist,
    verifyEmailSuccessfullNotExist,
    verifyEmailFail,
    resendCodeSuccessful,
    resendCodeFail,
    verifyCodeSuccessful,
    verifyCodeFail,
    socialLoginSuccessful,
    socialLoginFail,
    SubscriptionFreeCompanySuccessful,
    SubscriptionFreeCompanyFail,
    ShowCookiesModal,
    ShowPrivacyModal,
    ShowTermsModal,
    ScrollToHowItworks,
    NONE,
    SubscriptionAddOutSpecialSuccessful,
    SubscriptionAddOutSpecialFail,
    ContributionAddSuccessFul,
    ContributionFetchSuccessful,
    ContributionDeleteSuccessful,
    ContributionCommentAddSuccessful,
    ContributionCommentUpdateSuccessful,
    ContributionCommentPinSuccessful,
    ContributionCommentDeleteSuccessful,
    ContributionCommentRemoveFileSuccessful,
    ContributionReplyAddSuccessful,
    ContributionReplyUpdateSuccessful,
    ContributionReplyDeleteSuccessful,
    ContributionReplyRemoveFileSuccessful,
    Blink,
    ContributionCycleFetchSuccessful,
    SecondStep,
    FirstStep,
    VerifyPhoneNumberExistSuccessful,
    VerifyPhoneNumberExistFail,
    VerifyPhoneCodeSuccessful,
    VerifyPhoneCodeFail,
    PhoneCodeSuccessful,
}