import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IPortfolio } from '../models/portfolio';
@Injectable({
  providedIn: 'root'
})
export class SharesApiService {
  headers= {
    'X-RapidAPI-Key': '0f42725f6bmsh78635e10392c5dfp1917d5jsn071c8f6f7523',
    'X-RapidAPI-Host': 'twelve-data1.p.rapidapi.com'
  }
  constructor(private http:HttpClient) { }
  currency(symbol:string){
    const options = {
      headers: this.headers,
    };
    return this.http.get(`https://twelve-data1.p.rapidapi.com/price?outputsize=30&format=json&symbol=${symbol}/USD`,options)
  }
  fetchSharePrice(position:any){
    const options = {
      headers: this.headers,
    };
    return this.http.get(`https://twelve-data1.p.rapidapi.com/price?outputsize=30&format=json&symbol=${position.symbol}&mic_code=${position.mic_code}`,options)
  }
  fetchStockSymbol(name:string){
    const options = {
      headers: this.headers,
    };
    return this.http.get('https://twelve-data1.p.rapidapi.com/symbol_search?symbol='+name.replaceAll(' ','+').replaceAll('&','%26'),options)
  }
  addCompany(data:FormData){
    return this.http.post(environment.api+"portfolio/addCompany",data,{})
  }
  addFunds(funds:number){
    return this.http.get(environment.api+"portfolio/addfunds/"+`${funds}`,{})
  }
  fetchPortfolio(){
    return this.http.get<IPortfolio>(environment.api+"portfolio",{})
  }
  buyStocks(data:FormData){
    return this.http.post(environment.api+"portfolio/buy",data,{})
  }
  transferStock(data:FormData){
    return this.http.post(environment.api+"portfolio/transfer",data,{})
  }
  sellStocks(data:FormData){
    return this.http.post(environment.api+"portfolio/sell",data,{})
  }
  synchronize(){
    return this.http.get<IPortfolio>(environment.api+"portfolio/synchronize",{})
  }
  fetchStockTimeSeries(name:string){
    const options = {
      headers: this.headers,
    };
    return this.http.get('https://twelve-data1.p.rapidapi.com/time_series?interval=1month&outputsize=12&format=json&symbol='+name,options)
  }
  transactionHistory(){
    return this.http.get(environment.api+"portfolio/selandBuyHistorical",{})
  }
  monthlyPerformance(year:any,month:any){
    return this.http.get(environment.api+`portfolio/MonthlyPerformance/${year}/${month}`,{})
  }
  yearlyPerformance(year){
    return this.http.get(environment.api+"portfolio/YearlyPerformance/"+year,{})
  }
  monthlyPerformanceIntrinsic(year:any,month:any){
    return this.http.get(environment.api+`portfolio/MonthlyPerformanceintrinsec/${year}/${month}`,{})
  }
  yearlyPerformanceIntrinsic(year:any){
    return this.http.get(environment.api+"portfolio/YearlyPerformanceintrinsec/"+year,{})
  }
  setNeutral(position_id:string){
    return this.http.get(environment.api+"historicalPrices/set_is_neutral/"+position_id)
  }
  setIntrensic(position_id:string,value:number,date:any){
    const form = new FormData()
    form.append('fixed_price',`${value}`)
    form.append('date',`${date}`)
    return this.http.post(environment.api+"historicalPrices/store/"+position_id,form)
  }
  getYears(){
    return this.http.get(environment.api+"portfolio/YearlyPerformanceYears",{})
  }
  updatePositionInfo(data:any,id:string){
    const form = new FormData()
    form.append("country",data.country);
    form.append("currency",data.currency);
    form.append("exchange",data.exchange);
    form.append("symbol",data.symbol);
    form.append("mic_code",data.mic_code);
    form.append("company_id",id);
    return this.http.post(environment.api+"portfolio/updateCompanyInfo",form,{})
  }
}
